import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { LogoDesignSystem, LogoDigital, LogoDigitalCRM, LogoFindRoommate, LogoHseCareer, LogoSmartAssistant, LogoSmartBoss, LogoSmartData, LogoSmartDoc, LogoSmartLms, LogoSmartOffice, LogoSmartPoint, LogoSmartReg, LogoSmartSecurity, LogoSmartEvent } from '@hse-design/react';
import { colorBackgroundGray1 } from '@hse-design/tokens';
import { LogoDemo, P } from './common';
import favicon from '@hse-design/core/lib/assets/favicon/basic.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "логотипы"
    }}>{`Логотипы`}</h1>
    <p>{`Мы рекомендуем использовать одноименные компоненты для вставки логотипа. Пример использования находится `}<a parentName="p" {...{
        "href": "http://localhost:3005/src-components-logo-digital"
      }}>{`в документации`}</a>{`.`}</p>
    <p>{`Также все логотипы, перечисленные ниже, можно `}<a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/1tX4pt13yHh9WGqokd4jgmMDBSSEf1oS-"
      }}>{`скачать по ссылке`}</a>{`.`}</p>
    <h2 {...{
      "id": "логотип-вышкиdigital"
    }}>{`Логотип Вышки.Digital`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap'
    }}>
  <LogoDemo logo={LogoDigital} label='Логотип Вышка.Digital на русском' mdxType="LogoDemo" />
  <LogoDemo logo={LogoDigital} logoProps={{
        lang: 'eng'
      }} label='Логотип Вышка. Digital на английском' mdxType="LogoDemo" />
    </div>
    <P mdxType="P">
  Специальное подразделение Вышка.Digital имеет свой собственный логотип.
  <br />
  <a href='https://www.dropbox.com/s/yk8ipg5j9tq605t/Guideline%28v8%29_29.04RGB_NF.pdf?dl=0' target='_blank'>Брендбук</a>
    </P>
    <h2 {...{
      "id": "логотипы-продуктов"
    }}>{`Логотипы продуктов`}</h2>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap'
    }}>
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartBoss} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartData} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartLms} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartOffice} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartPoint} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartSecurity} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoFindRoommate} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartDoc} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoDesignSystem} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartAssistant} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoDigitalCRM} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoHseCareer} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartReg} mdxType="LogoDemo" />
  <LogoDemo style={{
        height: '48px'
      }} logo={LogoSmartEvent} mdxType="LogoDemo" />
    </div>
    <p>{`В основе каждого логотипа продукта Вышки.Digital лежит основной знак, а отличия кодируются цветом. Как и у логотипа
Вышки.Digital, у каждого из логотипов продуктов есть цветная и
черно-белая версии, версии на русском и английском.`}</p>
    <p>{`Об использовании логотипов в React читайте в документации React-компонента `}<a parentName="p" {...{
        "href": "/src-components-logo-digital"
      }}>{`LogoDigital`}</a>{`.`}</p>
    <h2 {...{
      "id": "фавикон"
    }}>{`Фавикон`}</h2>
    <div style={{
      display: 'flex',
      background: colorBackgroundGray1,
      padding: '16px'
    }}>
  <img style={{
        flexShrink: '0'
      }} width={'64px'} height={'64px'} src={favicon} />
    </div>
    <p>{`Все продукты Вышки.Digital используют одинаковый фавикон для сохранения принадлежности
к диджитал сервису. Данный фавикон контрастен по отношению к основному логотипу ВШЭ.`}</p>
    <p>{`Для подключения фавикона перенесите в корень сайта содержимое директории
`}<a parentName="p" {...{
        "href": "https://gitlab.com/proscom/hse-design/-/tree/master/packages/core/src/assets/favicon"
      }}><inlineCode parentName="a">{`lib/assets/favicon`}</inlineCode></a>{`
пакета `}<inlineCode parentName="p">{`@hse-design/core`}</inlineCode>{`. Содержимое файла с метатегами `}<inlineCode parentName="p">{`head.html`}</inlineCode>{` нужно добавить внутрь тега `}<inlineCode parentName="p">{`head`}</inlineCode>{`.
В файле `}<inlineCode parentName="p">{`site.webmanifest`}</inlineCode>{` пропишите параметры `}<inlineCode parentName="p">{`name`}</inlineCode>{` и `}<inlineCode parentName="p">{`short_name`}</inlineCode>{`, соответствующие разрабатываемому продукту.
Если вы используете готовый шаблон, например `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`, то в `}<inlineCode parentName="p">{`head`}</inlineCode>{` скорее всего уже если некоторые метатеги.
Удалите все дубликаты, оставив преимущество за содержимым файла `}<inlineCode parentName="p">{`head.html`}</inlineCode>{`.
В случае `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` можно также удалить оригинальный файл манифеста `}<inlineCode parentName="p">{`public/manifest.json`}</inlineCode>{`, так как вместо него
будет использован `}<inlineCode parentName="p">{`site.webmanifest`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      